<template>
  <div v-if="children" class="block docx-image-block">
    <div
      class="image-block"
      :ref="`image-${children.block}`"
      :class="getAlignFlex(children.blockAttr.image.align)"
    >
      <div
        class="image-block-width-wrapper flash-block-content"
        :style="`width: ${imgStyle.width}px;`"
      >
        <div
          class="image-block-container"
          :style="`width: ${imgStyle.width}px;padding-top: ${
            (imgStyle.height / imgStyle.width) * 100
          }%;`"
        >
          <div class="resizable-wrapper">
            <div :ref="`img${children.blockId}`" class="img" 
              :class="showBorder ? 'animate__animated animate__heartBeat':''" 
              :style="{cursor: checkIframe ? 'pointer' : 'zoom-in'}"
            >
              <!-- <el-image
                alt="加载失败"
                class="fix-mask-image"
                @click.stop="previewShow"
                @error="errorImg"
                :preview-src-list="previewSrcList"
                :src="imgUrl"
                :style="imgStyle"
              ></el-image> -->
              <template v-for="(area, areaIndex) in textArea">
                <div
                  v-if="searchKey && area.text.indexOf(searchKey) > -1"
                  class="area-box"
                  :class="
                    children.blockId == currentBlockId && currentSign == getAreaIndex(areaIndex)
                      ? 'tag-img-active'
                      : ''
                  "
                  :id="`${children.blockId}-${getAreaIndex(areaIndex)}`"
                  :style="{
                    top:
                      (area.y *
                        (Math.min(imgStyle.width, maxWidth) *
                          (imgStyle.height / imgStyle.width))) /
                        children.blockAttr.image.height +
                      'px',
                    left:
                      (area.x * Math.min(imgStyle.width, maxWidth)) /
                        children.blockAttr.image.width +
                      'px',
                    width:
                      (area.width * Math.min(imgStyle.width, maxWidth)) /
                        children.blockAttr.image.width +
                      'px',
                    height:
                      (area.height *
                        (Math.min(imgStyle.width, maxWidth) *
                          (imgStyle.height / imgStyle.width))) /
                        children.blockAttr.image.height +
                      'px',
                  }"
                ></div>
              </template>

              <img :src="imgUrl" :style="imgStyle" :alt="imgStyle.alt" @click.stop="showPreview" />
              <div v-if="showIframe" class="iframe-container" v-loading="loading">
                <!-- <i class="close-iframe el-icon-error" @click="handleIframe = false"></i> -->
                <iframe :ref="`iframe${children.blockId}`" class="iframe-tool" @load="iframeLoad" :src="checkIframe" frameborder="0"></iframe>
              </div>
            </div>
          </div>
        </div>
        <div v-if="checkIframe" class="caption-editor-area">
          <span>点击图片使用{{checkToolName}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAlignFlex } from "../utils/style";
import * as bekit from "bekit";
const { Ua } = bekit.helper;
export default {
  name: "img-block",
  props: {
    children: {
      type: Object,
      default: null,
    },
  },
  inject: ["previewSrcList", "isVirt"],
  data() {
    const isMobile = Ua.isMob();
    return {
      getAlignFlex,
      innerHeight: window.innerHeight,
      updateUrl: "",
      maxWidth: 800,
      handleIframe: false,
      loading: true,
      isMobile
    };
  },
  computed: {
    searchResultIndex() {
      return this.$store.state.searchResultIndex;
    },
    searchPageBlockIds() {
      return this.$store.state.searchPageBlockIds;
    },
    currentBlockId() {
      let index = this.searchResultIndex - 1;
      if (index < 0) {
        return "";
      }
      return (
        this.searchPageBlockIds[index] && this.searchPageBlockIds[index].blockId
      );
    },
     currentSign() {
      let index = this.searchResultIndex - 1;
      if (index < 0) {
        return 0;
      }
      return (
        this.searchPageBlockIds[index] && this.searchPageBlockIds[index].index
      );
    },
    searchKey() {
      return this.$store.state.searchKey || "";
    },
    proxy() {
      return this.children.blockAttr.proxy || 0;
    },
    textArea() {
      return this.children.blockAttr.textArea || [];
    },
    imgUrl() {
      if(this.isVirt){
        return ''
      }
      if (this.proxy) {
        return this.children.blockAttr.image.url;
      }
      return (
        this.children.blockAttr.cdnUrl ||
        this.updateUrl ||
        `/api/feishu/media/${this.children.blockAttr.image.token}`
      );
    },
    imgStyle() {
      let height =
        (this.children.blockStyles && this.children.blockStyles.imgHeight) ||
        this.children.blockAttr.image.height;
      let tempH = height;
      let width =
        (this.children.blockStyles && this.children.blockStyles.imgWidth) ||
        this.children.blockAttr.image.width;
      let alt = (this.children.blockStyles && this.children.blockStyles.alt) || '';
      // if (height > this.innerHeight / 2) {
      //   height = this.innerHeight / 2;
      //   width = (width * height) / tempH;
      // }
      return { height, width, alt };
    },
    checkIframe(){
      let alt = this.imgStyle.alt;
      const reg = /^一堂小工具\[.*\]\((.+)\)$/;
      const match = alt.match(reg);
      let value = ''
      if (match) {
          value = match[1]; 
      }
      return value 
    },
    checkToolName(){
      let alt = this.imgStyle.alt;
      const reg = /^一堂小工具\[(.*)\]\(.+\)$/;
      const match = alt.match(reg);
      let value = ''
      if (match) {
          value = match[1]; 
      }
      return value 
    },
    showIframe(){
      return this.checkIframe && this.handleIframe
    },
    showBorder(){
      return this.textArea.some((area, areaIndex) => {
        return this.searchKey && area.text.indexOf(this.searchKey) > -1 
        && this.children.blockId == this.currentBlockId 
        && this.currentSign == this.getAreaIndex(areaIndex)
      })
    }
  },
  mounted(){
    this.$nextTick(()=>{
      let $ele = this.$refs[`image-${this.children.block}`]
      let width = $ele.offsetWidth
      this.maxWidth = width
    })
   
  },
  methods: {
    iframeLoad(){
      this.loading = true;
      try {
        const iframe = this.$refs[`iframe${this.children.blockId}`];
        const img = this.$refs[`img${this.children.blockId}`];
        const doc = iframe.contentDocument || iframe.contentWindow.document;
        const innerHeight = doc.body.offsetHeight - 16; //16 为上下margin 8
        const processButton = doc.querySelector('#processButton')
        const canvas = doc.querySelector('#treeCanvas')
        let zoom = (img.offsetWidth - 16) / (canvas.offsetWidth);
        let node = this.isMobile ? doc.body : canvas
        if(zoom < 1){
          if(this.isMobile){
            node.style.marginBottom = `-${innerHeight - innerHeight * zoom}px`
          }else{
            node.style.marginBottom = `-${canvas.offsetHeight - canvas.offsetHeight * zoom}px`
          }
          node.style.transformOrigin = 'top left'
          node.style.transform = `scale(${zoom})`
          // doc.body.style.zoom = zoom
        }
        processButton.addEventListener('click',() => {
          let canvaszoom = (img.offsetWidth - 16) / (canvas.offsetWidth * (this.isMobile ? zoom : 1));
          if(canvaszoom < 1){
          canvas.style.transformOrigin = 'top left'
          canvas.style.transform = `scale(${canvaszoom})`
          // canvas.style.marginBottom = `-${(1 - canvaszoom) * 100}%`
            // canvas.style.zoom = canvaszoom
          }
        })
        this.hideLoading()
      } catch (error) {
        this.hideLoading()
      }
    },
    hideLoading(delay = 400){
      setTimeout(() => {
        this.loading = false;
      }, delay)
    },
    getAreaIndex(index){
      let sum = 0
      for(let i = 0; i <= index; i++){
        let element = this.textArea[i]
        if(element.text.indexOf(this.searchKey) > -1){
          sum++
        }
      }
      return sum - 1
    },
    showPreview() {
      if(this.checkIframe){
        this.handleIframe = true;
        $.stat.add('action', {
            name: 'click_img_tool_business_formula'
        });
        return
      }
      this.$store.commit("setPerviewImageUrl", this.imgUrl);
    },
    errorImg() {
      if (this.proxy) return;
      this.updateUrl = `/api/feishu/media/${this.children.blockAttr.image.token}?forceUpdate=1`;
    },
    previewShow() {
      this.stopAddUpNode();
      this.$nextTick(() => {
        let domImageView = document.querySelector(".el-image-viewer__mask"); // 获取遮罩层dom
        let closeEle = document.querySelector(".el-image-viewer__close"); // 获取遮罩层关闭按钮dom
        if (!domImageView || !closeEle) {
          return;
        }
        domImageView.addEventListener("click", () => {
          this.removeUpNode();
        });
        closeEle.addEventListener("click", () => {
          this.removeUpNode();
        });
      });
    },
    handleEvent(e) {
      e = e || window.event;
      if ((e.wheelDelta && event.ctrlKey) || e.detail) {
        event.preventDefault();
      }
    },
    stopAddUpNode() {
      document.addEventListener("mousewheel", this.handleEvent, {
        capture: false,
        passive: false,
      });
    },
    removeUpNode() {
      document.removeEventListener("mousewheel", this.handleEvent, {
        capture: false,
        passive: false,
      });
    },
  },
};
</script>
<style scoped>
.image-block {
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.image-block-width-wrapper {
  max-width: 100%;
  position: relative;
}
.image-block-container,
.image-block-width-wrapper {
  max-width: 100%;
  position: relative;
}
.image-block-container {
  min-width: 32px;
}
.image-block-container .resizable-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.image-block-container .img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  min-width: 32px;
  padding: 0;
  border-color: transparent;
  user-select: none;
  -webkit-user-select: none;
}
.image-block-container .img /deep/ img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  display: block;
}
.img /deep/.el-image img {
  width: 100%;
  height: 100%;
}
.fix-mask-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}
.area-box {
  position: absolute;
  z-index: 1;
  outline: 4px solid rgba(255, 198, 10, 0.5) ;
}
.tag-img-active {
  outline-color: rgba(245, 74, 69, 0.5);
}
.iframe-container{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF;
  z-index: 1;
}
.iframe-container .iframe-tool{
  height: 100%;
  width: 100%;
}
.iframe-container .close-iframe{
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.caption-editor-area{
  margin-top: 6px;
  text-align: center;
}
.caption-editor-area span{
  color:rgb(100, 106, 115);
  font-size: 14px;
}
</style>